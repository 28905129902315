<template>
  <div class="deposit-log-view">
    <div class="top flex-ac flex-jb">
      <!-- <select class="select" v-model="monthDate">
        <option
          v-for="month in month_list"
          :value="month"
          :key="month + 'option'"
        >
          {{ month.split('-')[0] }} 年 {{ month.split('-')[1] }} 月
        </option>
      </select>
      <month-picker @setDate="setDate"></month-picker>
      -->
        <div class="hint">
          最新五笔
        </div>
    </div>
    <div class="content">
      <a-spin :spinning="loading">
        <div v-if="list.length > 0" class="table">
          <div
            v-for="(item, idx) in list"
            :key="idx"
            class="table-row flex-ac flex-jb log-item"
          >
            <p class="title">单号： {{ item.vip_pay_id }}</p>
            <div class="detail">
              <div>
                <p>普通充值&nbsp;({{ item.status_name }})</p>
                <p>充值方式：&nbsp;{{ item.type_name }}</p>
              </div>
              <div>
                <p v-text="item.record_date"></p>
                <p>&plus;{{ item.score_cash }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">暫無數據</div>
        <div v-if="showPagination" class="pages flex-ac flex-jb">
          <div class="page-btns flex-c">
            <div class="page-btn" @click="firstPage">首页</div>
            <div class="page-btn" @click="prevPage">上一页</div>
            <div class="page-btn" @click="nextPage">下一页</div>
            <div class="page-btn" @click="lastPage">末页</div>
          </div>
          <!--<div class="page-index">第 {{ pages }} 页 共 {{ totalPages }} 页</div>-->
        </div>
      </a-spin>
      <div class="bottom">
        <div class="hint">
          温馨提示：<br />
          充值到賬有时可能存在延遲，感謝您的理解，如有疑
          问請聯繫客服，我们将幾時为您核實处理。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthPicker from '@/components/month-picker.vue'
import { post } from '@/axios'
import pagination from '@/mixins/pagination'

export default {
  name: 'deposit_log',
  components: {
    MonthPicker
  },
  mixins: [pagination],
  data() {
    return {}
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      const mapStr = JSON.stringify({
        pages: this.pages,
        rows: 5
      })

      try {
        this.loading = true
        const { code, info, data } = await post(
          'record_rechargeRecord.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { rechargeList, total_num } = JSON.parse(data)
          this.total_num = total_num
          this.list = rechargeList
        }
      } catch {
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    pages() {
      this.getData()
    },
    monthDate() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.deposit-log-view {
  flex-direction: column;
}

.content {
  width: 100%;
}

.table {
  width: 100%;
  margin: 0.5rem 0;

  .table-row {
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 1px;
    padding: 1rem 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      margin-bottom: 0.5rem;
    }

    .detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 1.2;

      > div:last-child {
        text-align: right;
      }
    }
  }
}

.bottom {
  width: 90%;
  height: 6rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  margin: 0 auto 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
  font-size: 0.9rem;
  margin-top: 1rem;
  user-select: none;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .table-row {
    color: #999;
    padding: 0.5rem;

    &:hover {
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
  }
}
</style>
